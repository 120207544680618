import { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { match } from "ts-pattern";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { CheckRounded, CloseRounded, ContentCopyRounded, DeleteRounded, LockRounded } from "@mui/icons-material";
import { capitalize } from "lodash";
import { currencyToLocale, formattedPrice } from "../../helpers/money";
import { useAppState } from "../../state";
import { colors, fonts } from "../../theme";
import Button from "../Button";
import { Icon } from "../Icon";
import { Container } from "../Layout";
import { Text } from "../Text";
import Dropdown from "../Form/Dropdown";
import { getSizeAndFrame, sizeLabelsForCountry } from "../../helpers/product";
import { Frame, RectangleSize, Size } from "../../types/product";
import { trackEvent } from "../../helpers/analytics";
import AuthContext from "../../state/auth";
import { giftCardId } from "../../state/constants";
import {
  BasketFragment,
  BasketLineItemFragment,
  CountryCode,
  useGetProductByHandleQuery,
  useUpdateItemInBasketMutation,
} from "../../generated/storefront";
import FrameMockUp from "../FrameMockUp";

type ContentProps = {
  basket?: BasketFragment;
  onClose: () => void;
  viewOnly?: boolean;
};

type Props = {
  open: boolean;
  basket?: BasketFragment;
  onClose: () => void;
  viewOnly?: boolean;
};

const StickyBottom = styled.div`
  position: sticky;
  width: 100%;
  bottom: 0;
  background-color: ${colors.white};
`;

const Bar = styled.div<{ color: string; opacity: number }>`
  width: 100%;
  height: 4px;
  border-radius: 100px;
  background-color: ${({ color }) => color};
  opacity: ${({ opacity }) => opacity};
`;

const FreeDeliveryBar = styled.div<{ percent: number }>`
  width: 100%;
  height: 4px;
  border-radius: 100px;
  background-color: ${colors.grey10};

  &::after {
    content: "";
    display: block;
    width: ${({ percent }) => percent}%;
    height: 100%;
    border-radius: 100px;
    background-color: ${({ percent }) => (percent === 100 ? colors.green : colors.red)};
  }
`;

const BasketContent = ({ basket, onClose, viewOnly }: ContentProps) => {
  const { setBasket, freeDeliveryThreshold, inFreeDeliveryCountry, selectedCountry } = useAppState();
  const { isAdmin } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const items = basket?.lines?.nodes.length || 0;
  const [updateItemInBasket] = useUpdateItemInBasketMutation();
  const disableEdit = viewOnly && !isAdmin;
  const { data: mountingProductData } = useGetProductByHandleQuery({
    fetchPolicy: "cache-and-network",
    variables: { handle: "mounting", country: selectedCountry },
  });
  const mountingProduct = useMemo(() => mountingProductData?.product, [mountingProductData]);
  const mountedLabel = match(selectedCountry)
    .with(CountryCode.Us, () => "Matted")
    .otherwise(() => "Mounted");

  if (basket && items > 0) {
    const items = basket.lines.nodes;
    const basketMainItems = basket?.lines.nodes.filter((l) => l.merchandise.product.title !== "Mounting") || [];
    const basketCount = basketMainItems.reduce((total, i) => total + i.quantity, 0);
    const basketSubtotal = basket.cost.subtotalAmount;
    const basketSubtotalAmount = basket.lines.nodes.reduce((total, i) => total + Number(i.cost.subtotalAmount.amount), 0);
    const basketTotalAmount = basket.lines.nodes.reduce((total, i) => total + Number(i.cost.totalAmount.amount), 0);
    const basketTotal = basket.cost.totalAmount;
    const basketSubtotalPrice = formattedPrice(basketSubtotalAmount, basketSubtotal.currencyCode);
    const discountApplied = basketSubtotalAmount > basketTotalAmount;
    const freeDeliveryDifference = freeDeliveryThreshold - basketTotalAmount;
    const freeDeliveryPercentage = Math.min((basketTotalAmount / freeDeliveryThreshold) * 100, 100);
    const dropdownOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((n) => ({
      value: n,
      label: n.toString(),
    }));

    const getVariantName = (item: Pick<BasketLineItemFragment, "merchandise" | "attributes">) => {
      if (item.merchandise.product.id === giftCardId) return "Gift card";
      const { size, frame } = getSizeAndFrame(item.merchandise.selectedOptions);
      const sizeLabels = sizeLabelsForCountry(selectedCountry);
      const finishCopy = item.attributes.find((a) => a.key === "mounted") ? ` ${mountedLabel}` : "";
      return `${sizeLabels[size as Size].shortHand}${finishCopy} / ${capitalize(frame)}${
        frame !== Frame.Unframed ? " Frame" : ""
      }`;
    };

    const getFrame = (item: Pick<BasketLineItemFragment, "merchandise">) => {
      if (item.merchandise.product.id === giftCardId) return Frame.Unframed;
      const { frame } = getSizeAndFrame(item.merchandise.selectedOptions);
      return frame;
    };

    const getSize = (item: Pick<BasketLineItemFragment, "merchandise">) => {
      if (item.merchandise.product.id === giftCardId) return RectangleSize.A3;
      const { size } = getSizeAndFrame(item.merchandise.selectedOptions);
      return size;
    };

    const onCheckout = () => {
      if (basket) {
        setLoading(true);
        trackEvent("InitiateCheckout");
      }
    };

    const removeItem = async (itemId: string) => {
      const item = items.find((i) => i.id === itemId);
      if (!item) return;
      const mounted = item?.attributes.find((a) => a.key === "mounted");
      const linesToUpdate = [{ id: itemId, quantity: 0 }];
      if (mounted) {
        const size = getSize(item);
        const mountedItem = items.find((i) => i.merchandise.sku === `M-${size}`);
        if (mountedItem) {
          linesToUpdate.push({ id: mountedItem.id, quantity: mountedItem.quantity - item.quantity });
        }
      }
      const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lines: linesToUpdate } });
      if (data?.cartLinesUpdate?.cart) {
        if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
        setBasket(data.cartLinesUpdate.cart);
      }
    };

    const updateQuantity = async (quantity: number, itemId: string) => {
      if (quantity === 0) return removeItem(itemId);
      const item = items.find((i) => i.id === itemId);
      if (!item) return;
      const mounted = item?.attributes.find((a) => a.key === "mounted");
      const linesToUpdate = [{ id: itemId, quantity }];
      if (mounted) {
        const size = getSize(item);
        const mountedItem = items.find((i) => i.merchandise.sku === `M-${size}`);
        const diff = quantity - item.quantity;
        if (mountedItem) {
          linesToUpdate.push({ id: mountedItem.id, quantity: mountedItem.quantity + diff });
        }
      }
      const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lines: linesToUpdate } });
      if (data?.cartLinesUpdate?.cart) {
        if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
        setBasket(data.cartLinesUpdate.cart);
      }
    };

    const getBasketItemPrice = (item: Pick<BasketLineItemFragment, "merchandise" | "attributes" | "cost">) => {
      const mountingVariant = mountingProduct?.variants.nodes.find(
        (variant) => variant.selectedOptions[0].value === getSize(item)
      );
      if (item.attributes.find((a) => a.key === "mounted")) {
        const price = Number(item.cost.subtotalAmount.amount) + Number(mountingVariant?.price.amount || 0);
        return formattedPrice(price, item.cost.subtotalAmount.currencyCode);
      }
      return formattedPrice(item.cost.subtotalAmount.amount, item.cost.subtotalAmount.currencyCode);
    };

    return (
      <>
        <Stack height="calc(100% - 76px)">
          <Box padding={{ xs: 2, md: 3 }} flex={1}>
            {items.map((item) =>
              item.merchandise.product.title.includes("Mounting") ? null : (
                <Container key={item.id} padding="16px 0">
                  <Stack gap={3} direction="row">
                    <Link to={`/products/${item.merchandise.product.handle}`} style={{ width: "40%", display: "flex" }}>
                      {getFrame(item) && (
                        <FrameMockUp
                          image={item.merchandise.product.images.nodes[0]}
                          frame={getFrame(item)}
                          size={getSize(item)}
                          mounted={Boolean(item.attributes.find((a) => a.key === "mounted"))}
                        />
                      )}
                    </Link>
                    <Stack width="60%">
                      <Text size={16} margin="0">
                        {item.merchandise.product.title}
                      </Text>
                      <Text size={14} color={colors.grey40} margin="0">
                        {getVariantName(item)}
                      </Text>
                      <Text size={14} margin="0 0 16px 0">
                        {getBasketItemPrice(item)}
                      </Text>
                      <Stack direction="row" gap={1} alignItems="center">
                        <Dropdown
                          width="fit-content"
                          options={dropdownOptions}
                          onChange={(event) => updateQuantity(event.value, item.id)}
                          value={dropdownOptions.find((option) => option.value === item.quantity)}
                          disabled={disableEdit}
                        />
                        <IconButton onClick={() => removeItem(item.id)} disabled={disableEdit}>
                          <DeleteRounded color={disableEdit ? "disabled" : "primary"} fontSize="small" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Stack>
                </Container>
              )
            )}
          </Box>
          <StickyBottom>
            <Link to="/shop" style={{ width: "100%" }}>
              <Box padding={2} borderTop={`1px solid ${colors.grey10}`}>
                <Stack direction="row" gap={1.5} alignItems="center">
                  <Stack sx={{ opacity: basketCount > 1 ? 0.2 : 1 }}>
                    <Icon icon="checkmark" size={16} width={22.84} fill={colors.green} />
                  </Stack>
                  <Bar color={basketCount > 1 ? colors.green : colors.grey10} opacity={basketCount > 2 ? 0.2 : 1} />
                  {basketCount > 1 ? (
                    <Stack sx={{ opacity: basketCount > 2 ? 0.2 : 1 }}>
                      <Icon icon="checkmark" size={16} fill={colors.green} />
                    </Stack>
                  ) : (
                    <LockRounded fontSize="small" color="disabled" />
                  )}
                  <Bar color={basketCount > 2 ? colors.green : colors.grey10} opacity={1} />
                  {basketCount > 2 ? (
                    <Stack>
                      <Icon icon="checkmark" size={16} fill={colors.green} />
                    </Stack>
                  ) : (
                    <LockRounded fontSize="small" color="disabled" />
                  )}
                </Stack>
                <Stack direction="row" alignItems="start" justifyContent="space-between" gap={2} paddingTop={0.5}>
                  <Stack flex={1} sx={{ opacity: basketCount > 1 ? 0.2 : 1 }}>
                    <Typography align="left" fontSize={12} fontFamily={fonts.disclaimer} color={colors.green} whiteSpace="nowrap">
                      1 item
                    </Typography>
                  </Stack>

                  <Stack flex={1} sx={{ opacity: basketCount > 2 ? 0.2 : 1 }}>
                    <Typography
                      align="center"
                      fontSize={12}
                      fontFamily={fonts.disclaimer}
                      color={basketCount >= 2 ? colors.green : colors.grey40}
                      whiteSpace="nowrap"
                    >
                      2 items
                    </Typography>
                    <Typography
                      align="center"
                      fontSize={12}
                      fontFamily={fonts.disclaimer}
                      color={basketCount >= 2 ? colors.green : colors.black}
                      whiteSpace="nowrap"
                      flex={1}
                    >
                      15% off
                    </Typography>
                  </Stack>

                  <Stack flex={1}>
                    <Typography
                      align="right"
                      fontSize={12}
                      fontFamily={fonts.disclaimer}
                      color={basketCount >= 3 ? colors.green : colors.grey40}
                      whiteSpace="nowrap"
                    >
                      3 items
                    </Typography>
                    <Typography
                      align="right"
                      fontSize={12}
                      fontFamily={fonts.disclaimer}
                      color={basketCount >= 3 ? colors.green : colors.black}
                      whiteSpace="nowrap"
                      flex={1}
                    >
                      20% off
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </Link>

            {inFreeDeliveryCountry && (
              <Box padding={2} borderTop={`1px solid ${colors.grey10}`}>
                <FreeDeliveryBar percent={freeDeliveryPercentage} />
                <Typography
                  align="center"
                  fontSize={12}
                  fontFamily={fonts.disclaimer}
                  lineHeight="20px"
                  paddingTop={1}
                  color={freeDeliveryPercentage === 100 ? colors.green : colors.black}
                >
                  {freeDeliveryDifference > 0
                    ? `Spend ${formattedPrice(freeDeliveryDifference, basketSubtotal.currencyCode)} more for free delivery`
                    : `Free delivery applied (${formattedPrice(freeDeliveryThreshold, basketSubtotal.currencyCode, false)})`}
                </Typography>
              </Box>
            )}

            <Box
              paddingX={{ xs: 2, md: 3 }}
              paddingY={2}
              borderTop={`1px solid ${colors.grey10}`}
              borderBottom={`1px solid ${colors.grey10}`}
            >
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Text size={18}>Subtotal</Text>
                <Stack direction="row">
                  {discountApplied && (
                    <Text margin="0 6px 0 0">{formattedPrice(basketTotalAmount, basketTotal.currencyCode)}</Text>
                  )}
                  <Text linethrough={discountApplied}>{basketSubtotalPrice}</Text>
                </Stack>
              </Stack>
            </Box>
            <Box padding={{ xs: 2, md: 3 }} marginBottom={{ xs: window.innerHeight === window.outerHeight ? 0 : 6, md: 0 }}>
              <Stack gap={2}>
                <a href={basket.checkoutUrl}>
                  <Button onClick={onCheckout} fullWidth loading={loading}>
                    Checkout
                  </Button>
                </a>

                {/* @ts-ignore */}
                <klarna-placement
                  id="klarna-placement-credit-promotion-badge"
                  data-key="credit-promotion-badge"
                  data-locale={currencyToLocale(basketTotal.currencyCode)}
                  data-purchase-amount={`${basketTotalAmount * 100}`}
                >
                  {/* @ts-ignore */}
                </klarna-placement>

                {isAdmin && (
                  <Link to={`/admin/checkout?basketId=${basket.id}`}>
                    <Button fullWidth color={colors.purple}>
                      Admin checkout
                    </Button>
                  </Link>
                )}
              </Stack>
            </Box>
          </StickyBottom>
        </Stack>
      </>
    );
  } else {
    return (
      <Container padding="145px 24px">
        <Stack justifyContent="center">
          <Typography fontSize={18} margin="0 0 16px" align="center">
            Your basket is empty
          </Typography>
          <Link to="/shop" style={{ width: "100%" }}>
            <Button onClick={onClose} fullWidth>
              Shop
            </Button>
          </Link>
        </Stack>
      </Container>
    );
  }
};

const BasketDrawer = ({ open, basket, onClose, viewOnly }: Props) => {
  const [copied, setCopied] = useState(false);
  const basketMainItems = basket?.lines.nodes.filter((l) => l.merchandise.product.title !== "Mounting") || [];
  const basketCount = basketMainItems.reduce((total, i) => total + i.quantity, 0);

  const shareBasketLink = () => {
    if (basket) {
      const url = `${window.location.origin}?basket=${basket.id}`;
      navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={{ xs: "90vw", md: "25vw" }} height="100vh" overflow="auto" minWidth={{ xs: "auto", md: "450px" }}>
        <Stack
          paddingY={2}
          paddingX={{ xs: 2, md: 3 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`1px solid ${colors.grey10}`}
        >
          <Typography variant="h3">Basket ({basketCount})</Typography>
          <Stack direction="row" gap={1}>
            <IconButton
              onClick={shareBasketLink}
              disabled={!basket?.lines.nodes.length}
              style={{ backgroundColor: colors.grey02 }}
            >
              {copied ? (
                <CheckRounded color="primary" />
              ) : (
                <ContentCopyRounded color={basket?.lines.nodes.length ? "primary" : "disabled"} />
              )}
            </IconButton>
            <IconButton onClick={onClose} style={{ backgroundColor: colors.grey02 }}>
              <CloseRounded color="primary" />
            </IconButton>
          </Stack>
        </Stack>
        <BasketContent basket={basket} onClose={onClose} viewOnly={viewOnly} />
      </Box>
    </Drawer>
  );
};

export default BasketDrawer;
