export const reviews = [
  {
    id: 1,
    comment:
      '"Very happy customer here - shipped & delivered exactly in the promised timeframe, great packaging that protected the art without going overboard. Amazing art selection and no import duties to the EU! I couldn’t be happier, thank you so much GoodMood!! :)"',
    rating: 5,
  },
  {
    id: 2,
    comment:
      '"Excellent quality prints with colours that really pop. I love the range of Artists that GoodMood has available. Highly recommended!"',
    rating: 5,
  },
  {
    id: 3,
    comment: '"Gorgeous prints, excellent service and communication."',
    rating: 5,
  },
  {
    id: 4,
    comment:
      "\"I'm delighted with my prints, they look fantastic on the wall! The website was a particular highlight, user-friendly and very easy to view products. Speedy and flawless delivery - I'll be back for more prints!\"",
    rating: 5,
  },
  {
    id: 5,
    comment: '"Really happy with my new prints from GoodMood - great prices, quick delivery and they look fab!"',
    rating: 5,
  },
];

export const overallRating = 4.9;
