import { useState } from "react";
import { useDebounce } from "react-use";
import { Link } from "react-router-dom";
import { Box, CircularProgress, Stack, Tooltip, Typography } from "@mui/material";
import { DataGridPro, GridRowOrderChangeParams } from "@mui/x-data-grid-pro";
import { ArtistProductFragment, BulkOrderFragment, CurrencyCode } from "../../../generated/graphql";
import { getProfitForProduct, getSalesForProduct } from "../helpers";
import { getIdNumber } from "../../../helpers/shopify";
import { formattedPrice } from "../../../helpers/money";
import { ArrowForwardRounded, DragHandleRounded, InfoOutlined } from "@mui/icons-material";
import { colors, fonts } from "../../../theme";
import TextLink from "../../../components/TextLink";
import { getPageViews } from "../../../services/API/analytics";
import { updateProduct } from "../../../services/API";
import { Status } from ".";

type Props = {
  isMobileScreen: boolean;
  products: ArtistProductFragment[];
  refetchProducts: () => void;
  artistOrders?: BulkOrderFragment[];
  loading: boolean;
  startDate: string;
  endDate: string;
  selectedStatus: Status;
};

const ProductGrid = ({
  isMobileScreen,
  products,
  refetchProducts,
  artistOrders,
  loading,
  startDate,
  endDate,
  selectedStatus,
}: Props) => {
  const [productPageViews, setProductPageViews] = useState<Record<string, number | undefined>>({});

  const columns = [
    {
      field: "piece",
      headerName: "Piece",
      flex: 1,
      renderCell: (params: any) => (
        <Link to={`/profile/dashboard/${getIdNumber(params.row.id)}`}>
          <Stack direction="row" gap={1} alignItems="center" height="100%">
            {params.row.image ? (
              <img src={params.row.image} alt={params.row.title} width="40px" height="auto" />
            ) : (
              <Box width={40} height="auto" bgcolor={colors.grey10} />
            )}
            <Typography fontSize={14}>{params.row.title}</Typography>
          </Stack>
        </Link>
      ),
    },
    {
      field: "sales",
      headerName: "Sales",
      flex: 0.5,
      renderCell: (params: any) =>
        artistOrders && !loading ? getSalesForProduct(artistOrders, getIdNumber(params.row.id)) : <CircularProgress size={20} />,
    },

    {
      field: "profit",
      headerName: "Profit",
      flex: 0.5,
      renderHeader: () => (
        <Stack direction="row" gap={1} alignItems="center">
          <Typography fontSize={12}>Profit</Typography>
          <Tooltip
            title={
              <Typography>
                Please note commission is calculated from the sale price so may vary depending on discounts and/or currency
                conversions
              </Typography>
            }
          >
            <InfoOutlined fontSize="inherit" />
          </Tooltip>
        </Stack>
      ),
      renderCell: (params: any) =>
        artistOrders && !loading ? (
          formattedPrice(getProfitForProduct(artistOrders, getIdNumber(params.row.id)), CurrencyCode.Gbp)
        ) : (
          <CircularProgress size={20} />
        ),
    },
    {
      field: "views",
      headerName: "Views",
      flex: 0.5,
      renderCell: (params: any) =>
        productPageViews[startDate + endDate + params.row.id] !== undefined ? (
          productPageViews[startDate + endDate + params.row.id]
        ) : (
          <CircularProgress size={20} />
        ),
    },
    {
      field: "edit",
      headerName: "",
      flex: 0.5,
      textAlign: "center",
      renderCell: (params: any) => (
        <TextLink
          to={`/profile/dashboard/${getIdNumber(params.row.id)}/edit`}
          style={{ display: "flex", alignItems: "center", height: "100%" }}
        >
          <Typography fontSize={12}>Edit</Typography>
        </TextLink>
      ),
    },
    {
      field: "view",
      headerName: "",
      flex: 0.5,
      renderCell: (params: any) => (
        <Link
          to={`/profile/dashboard/${getIdNumber(params.row.id)}`}
          style={{ display: "flex", alignItems: "center", height: "100%" }}
        >
          <ArrowForwardRounded id="arrow-forward" />
        </Link>
      ),
    },
  ];

  const rows = products.map((product) => ({
    id: product.id,
    title: product.title,
    image: product.images.nodes[0]?.src,
    edit: "Edit",
    view: "View",
  }));

  const handleRowOrderChange = async (rowOrderChange: GridRowOrderChangeParams) => {
    const { targetIndex, row } = rowOrderChange;
    const selectedProduct = products.find((product) => product.id === row.id);
    if (!selectedProduct) return;
    const updatedPieces = products.filter((product) => product.id !== row.id);
    updatedPieces.splice(targetIndex, 0, selectedProduct);
    const promises = updatedPieces.map(async (product, index) => {
      const updatePosition =
        !product.artistPosition || product.artistPosition.value === null || Number(product.artistPosition.value) !== index;
      if (updatePosition) {
        try {
          await updateProduct(product.id, {
            metafields: [{ namespace: "custom", key: "artist_position", value: index.toString() }],
          });
        } catch (error) {
          alert("Failed to update product position, please try again");
        }
      }
    });
    await Promise.all(promises);
    refetchProducts();
  };

  const fetchPageViews = async () => {
    for (const product of products) {
      if (productPageViews[startDate + endDate + product.id] !== undefined) return;

      const { data } = await getPageViews({
        page: "products",
        id: getIdNumber(product.id),
        handle: product.handle,
        startDate,
        endDate,
      });
      setProductPageViews((prev) => ({ ...prev, [startDate + endDate + product.id]: data?.pageViews }));
    }
  };

  useDebounce(fetchPageViews, 1000, [products, startDate, endDate]);

  return isMobileScreen ? (
    <Stack>
      {products.length > 0 ? (
        products.map((product) => (
          <Link to={`/profile/dashboard/${getIdNumber(product.id)}`} key={product.id}>
            <Stack
              direction="row"
              alignItems="center"
              gap={2}
              borderBottom={`1px solid ${colors.cardGrey}`}
              paddingX={1.5}
              paddingY={2}
            >
              {product.images.nodes.length ? (
                <img src={product.images.nodes[0].src} alt={product.title} width={50} height="auto" />
              ) : (
                <Box width={50} height={70} bgcolor={colors.grey10} />
              )}
              <Stack flex={1} gap={1} justifyContent="space-between">
                <Typography fontSize={16}>{product.title}</Typography>
                <Stack direction="row" gap={2} justifyContent="space-between">
                  <Stack flex={1}>
                    <Typography fontSize={14} fontFamily={fonts.body} fontWeight={500}>
                      {artistOrders && !loading ? (
                        formattedPrice(getProfitForProduct(artistOrders, getIdNumber(product.id)), CurrencyCode.Gbp)
                      ) : (
                        <CircularProgress size={20} />
                      )}
                    </Typography>

                    <Typography fontSize={10} fontFamily={fonts.body} color={colors.grey60}>
                      profit
                    </Typography>
                  </Stack>
                  <Stack flex={1}>
                    <Typography fontSize={14} fontFamily={fonts.body} fontWeight={500}>
                      {artistOrders && !loading ? (
                        getSalesForProduct(artistOrders, getIdNumber(product.id))
                      ) : (
                        <CircularProgress size={20} />
                      )}
                    </Typography>
                    <Typography fontSize={10} fontFamily={fonts.body} color={colors.grey60}>
                      sales
                    </Typography>
                  </Stack>
                  <Stack flex={1}>
                    <Typography fontSize={14} fontFamily={fonts.body} fontWeight={500}>
                      {productPageViews[startDate + endDate + product.id] !== undefined ? (
                        productPageViews[startDate + endDate + product.id]
                      ) : (
                        <CircularProgress size={20} />
                      )}
                    </Typography>
                    <Typography fontSize={10} fontFamily={fonts.body} color={colors.grey60}>
                      views
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Link>
        ))
      ) : (
        <Stack justifyContent="center" alignItems="center" height="100%" paddingY={3}>
          <Typography>No products</Typography>
        </Stack>
      )}
    </Stack>
  ) : (
    <DataGridPro
      rows={rows}
      columns={columns}
      rowHeight={75}
      columnHeaderHeight={36}
      disableColumnSorting
      disableColumnMenu
      disableColumnFilter
      disableRowSelectionOnClick
      rowReordering={selectedStatus === Status.Live}
      hideFooter
      onRowOrderChange={handleRowOrderChange}
      slots={{
        rowReorderIcon: () => <DragHandleRounded color="action" />,
        noRowsOverlay: () => (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <Typography>No products</Typography>
          </Stack>
        ),
      }}
      sx={{
        border: 0,
        borderTop: `1px solid ${colors.grey10}`,
        borderRadius: 0,
        "--DataGrid-rowBorderColor": colors.grey10,
        "--DataGrid-containerBackground": colors.cardGrey,
        ".MuiDataGrid-columnSeparator": { display: "none" },
        ".MuiDataGrid-columnHeaderTitle": { fontSize: "12px" },
        ".MuiDataGrid-overlayWrapperInner": { height: "150px !important" },
        ".MuiDataGrid-rowReorderCellPlaceholder": { opacity: 0 },
      }}
    />
  );
};

export default ProductGrid;
